import classNames from 'classnames';
import { ComponentProps, FC, useMemo } from 'react';

import { ContentfulContentAuthor, ContentfulObjectType } from 'src/data/Contentful/ContentfulTypes';
import { Image } from 'src/general/components/Image/Image';
import { MarkdownLegacy } from 'src/general/components/Markdown/MarkdownLegacy';
import { scaleDownHeadings } from 'src/general/helpers/scaleDownHeadings';
import { getLinkedEntry } from 'src/hygiene/sections/flexible-content/getLinkedEntry';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './RichText.module.scss';

// Scale down heading elements to ensure document structure makes sense.
const markdownComponentOptions: ComponentProps<typeof MarkdownLegacy>['components'] = scaleDownHeadings(3);

interface Props extends FlexibleSection {
  sectionData: {
    title?: string;
    maxColumns: '1' | '2' | '3';
    author?: string;
    content: string;
  };
}

export const RichTextLegacy: FC<Props> = ({ sectionData, linkedEntries, sectionBackground }) => {
  const { title, maxColumns, content } = sectionData;
  const author = useMemo(
    () =>
      sectionData.author
        ? getLinkedEntry<ContentfulContentAuthor>(
            sectionData.author,
            ContentfulObjectType.CONTENT_AUTHOR,
            linkedEntries,
          )
        : null,
    [sectionData.author, linkedEntries],
  );

  return (
    <section className={classNames(styles.root, 'RichText', getSectionClasses(sectionBackground, 'article'))}>
      {title && <h2 className={classNames(styles.title, 'c-fw-normal', 'c-fs-h4')}>{title}</h2>}
      {author && (
        <div className={styles.author}>
          <div className={styles.authorImageWrapper}>
            <Image width={48} height={48} alt={author.firstName} src={author.image.url} />
          </div>
          <p className={classNames(styles.authorName, 'c-fw-normal', 'c-fs-body1')}>
            {author.firstName} {author.lastName}
          </p>
        </div>
      )}
      <MarkdownLegacy
        className={classNames(styles.content, styles[`columns-${maxColumns}`])}
        components={markdownComponentOptions}
      >
        {content}
      </MarkdownLegacy>
    </section>
  );
};
