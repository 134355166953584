import { RecentFilterSearch } from './RecentSearch.type';

export const getFilter = (search: RecentFilterSearch) => {
  let captionText: string[] = [];
  const bodyTypeText = search.filters.bodyType?.map((item) =>
    Array.isArray(item) ? item.map(formatHierarchicalValue).join(', ') : formatHierarchicalValue(item),
  );
  const priceText = generateChipText(
    search.filters.priceCashMax,
    search.filters.priceCashMin,
    search.filters.priceFinMax,
    search.filters.priceFinMin,
  );
  // If there's makeModel, use it as title and the rest as caption
  if (search.filters.makeModel?.length) {
    if (bodyTypeText) {
      captionText.push(...bodyTypeText);
    }
    if (priceText) {
      captionText.push(priceText);
    }
    return {
      title: search.filters.makeModel.map(formatHierarchicalValue).join(', '),
      caption: captionText.join(', '),
    };
  }

  // If there's no makeModel and there's bodyType, prioritize bodyType and use it as title and price as caption if exists
  if (search.filters.bodyType?.length) {
    return {
      title: search.filters.bodyType.map(formatHierarchicalValue).join(', '),
      caption: priceText ?? '',
    };
  }

  // If there's only price, use price as title
  if (
    search.filters.priceCashMax ||
    search.filters.priceCashMin ||
    search.filters.priceFinMax ||
    search.filters.priceFinMin
  ) {
    return {
      title: priceText,
      caption: '',
    };
  }

  return { title: '', caption: '' };
};

const generateChipText = (priceCashMax?: string, priceCashMin?: string, priceFinMax?: string, priceFinMin?: string) => {
  const formatPrice = (price: string) => {
    const num = parseInt(price, 10);
    if (num > 1000) {
      return `$${num / 1000}k`;
    }
    return num > 1000 ? `$${num / 1000}k` : `$${num}`;
  };
  if (priceCashMin && priceCashMax) {
    return `${formatPrice(priceCashMin)} - ${formatPrice(priceCashMax)}`;
  }
  if (priceCashMax) {
    return `Up to ${formatPrice(priceCashMax)}`;
  }
  if (priceCashMin) {
    return `From ${formatPrice(priceCashMin)}`;
  }
  if (priceFinMin && priceFinMax) {
    return `${formatPrice(priceFinMin)} - ${formatPrice(priceFinMax)}/week`;
  }
  if (priceFinMax) {
    return `Up to ${formatPrice(priceFinMax)}/week`;
  }
  if (priceFinMin) {
    return `From ${formatPrice(priceFinMin)}/week`;
  }
};

const formatHierarchicalValue = (value: string) => {
  return value.replaceAll('>', ' ').toUpperCase();
};
