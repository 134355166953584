import { gql } from '@apollo/client';

import { MicrocopyKey } from '../microcopy/microcopyDictionary';
import { contentfulQueryFetch } from './helpers/contentfulQuery';

export interface ContentfulMicrocopy {
  contentKey: MicrocopyKey;
  content: string;
}

/**
 * Interface associated with listMicrocopyQuery
 */
export interface ListMicrocopyQuery {
  microcopyCollection: {
    __typename: 'MicrocopyCollection';
    items: ContentfulMicrocopy[];
  };
}

/**
 * Query to retrieve a collection of Microcopy
 */
const listMicrocopyQuery = gql`
  query GetPage($limit: Int!, $skip: Int!, $preview: Boolean!) {
    microcopyCollection(limit: $limit, skip: $skip, preview: $preview) {
      items {
        contentKey
        content
      }
    }
  }
`;

/**
 * Fetch a collection of Microcopy
 */
function listMicrocopyData(limit: number, skip: number, preview: boolean) {
  return contentfulQueryFetch<ListMicrocopyQuery>(
    listMicrocopyQuery,
    {
      revalidate: 3600,
      tags: ['microcopy'],
    },
    {
      limit,
      skip,
      preview,
    },
    preview,
  );
}

/**
 * Fetch all the Microcopy
 */
export async function listAllMicrocopyPages(preview: boolean) {
  const listAll: ContentfulMicrocopy[] = [];
  const pagintionLimit = 100;
  let totalReturnedItems = 0;
  let currentPage = 0;
  // Build the list of all the microcopy
  do {
    // await in a loop is required in this case
    // eslint-disable-next-line no-await-in-loop
    const result = await listMicrocopyData(pagintionLimit, currentPage * pagintionLimit, preview);

    listAll.push(...result.microcopyCollection.items);
    totalReturnedItems = result.microcopyCollection.items.length;
    currentPage += 1;
  } while (totalReturnedItems === pagintionLimit);
  return listAll;
}
