import { FC, ReactNode, useEffect, useRef } from 'react';

type Props = {
  children: ReactNode;
  onIntersecting: () => void;
  margins?: string;
  threshold?: IntersectionObserverInit['threshold'];
};

export const IntersectionObserverHandler: FC<Props> = ({ children, onIntersecting, margins, threshold }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current && typeof IntersectionObserver !== 'undefined') {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              onIntersecting();
            }
          });
        },
        { rootMargin: margins, threshold },
      );
      observer.observe(ref.current);
    }
  }, []);

  return <div ref={ref}>{children}</div>;
};
