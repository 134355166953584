'use client';

import { FC } from 'react';
import { Button } from 'src/general/components/Button/Button';
import { Checkbox } from 'src/general/components/forms/Input/atoms/OptionFieldInput';
import { carComparisonStore } from 'src/stores/carComparisonStore';
import { CarComparisonStoreError } from 'src/stores/carComparisonStore.types';

import { carComparisonDismissSnackbarAction } from 'src/car-comparison/helpers/carComparisonDismissSnackbarAction';
import { ToasterService } from 'src/general/components/Toaster/ToasterService';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

type Props = {
  sku: string;
  className?: string;
};

export const CarComparisonAdd: FC<Props> = ({ sku, className }) => {
  const { skus, addSku, removeSku } = carComparisonStore((state) => ({
    skus: state.skus,
    addSku: state.addSku,
    removeSku: state.removeSku,
  }));

  const isSelected = !!skus.find((value) => value === sku);

  const handleClick = () => {
    const promiseAction = isSelected ? removeSku : addSku;
    pushToDataLayer({
      event: `car_comparison-${isSelected ? 'remove_sku' : 'add_sku'}`,
      sku,
    });
    promiseAction(sku).catch((error) => {
      let errorMessage = 'An unknown error happened';
      if (error instanceof CarComparisonStoreError) {
        errorMessage = error.message;
      }
      pushToDataLayer({
        event: `car_comparison-error`,
        errorMessage,
      });
      ToasterService.error({
        message: errorMessage,
        action: {
          type: 'custom',
          actionComponent: carComparisonDismissSnackbarAction,
        },
      });
    });
  };

  return (
    <Button
      onClick={handleClick}
      className={className}
      variant="secondary"
      size="small"
      fullWidth
      startIcon={<Checkbox checked={isSelected} />}
      data-testid="PLP_CompareCar_checkBox"
    >
      Compare
    </Button>
  );
};
