import NextLink from 'next/link';
import { AnchorHTMLAttributes, ComponentProps, FC, useMemo } from 'react';

import { getLinkType } from 'src/general/helpers/getLinkType';

type LinkProps = (ComponentProps<typeof NextLink> | AnchorHTMLAttributes<HTMLAnchorElement>) & { href: string };

/**
 * A component for both internal & external links.
 * External & anchor links are rendered as plain <a> tags; Internal links will use the Next JS component.
 */
export const Link: FC<LinkProps> = ({ href, children, ...props }) => {
  const linkType = useMemo(() => getLinkType(href), [href]);

  switch (linkType) {
    case 'external':
      return (
        <a href={href as string} target="_blank" rel="noopener noreferrer" {...props}>
          {children}
        </a>
      );
    case 'anchor':
      return (
        <a href={href as string} {...props}>
          {children}
        </a>
      );
    case 'internal':
    default:
      return (
        <NextLink href={href as string} {...props}>
          {children}
        </NextLink>
      );
  }
};
