import classNames from 'classnames';
import { FC } from 'react';

import { ProductCardVariant } from 'src/constants';
import { Skeleton } from 'src/general/components/Skeleton/Skeleton';

import styles from './ProductCard.module.scss';

interface Props {
  variant?: ProductCardVariant;
}

export const ProductCardLoading: FC<Props> = ({ variant = 'plp' }) => (
  <li className={classNames(styles.root, styles[variant], styles.loading)}>
    <div className={classNames(styles.image, styles.imagePlaceholder)} />
    <p className={styles.model}>
      <Skeleton />
    </p>
    <p className={styles.variant}>
      <Skeleton size={50} />
    </p>
    <p className={styles.details}>
      <Skeleton size={40} />
      &ensp;•&ensp;
      <Skeleton size={40} />
    </p>
    <aside className={styles.controls}>{/* Empty */}</aside>
    <footer className={styles.footer}>
      <p className={styles.price}>
        <Skeleton size={50} />
      </p>
      <p className={styles.repayment}>
        <Skeleton />
      </p>
      {variant !== 'default' && (
        <aside className={styles.footerActions}>
          <div className={styles.footerActionButton}></div>
          <div className={styles.footerActionButton}></div>
        </aside>
      )}
    </footer>
  </li>
);
