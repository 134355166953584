import classNames from 'classnames';
import { FC } from 'react';

import { Image } from '../Image/Image';
import styles from './ProductImage.module.scss';

interface Props {
  src: string;
  alt: string;
  width?: number;
  imagePriority?: boolean;
  className?: string;
}

const IMAGE_ASPECT_RATIO = 16 / 9;

export const ProductImage: FC<Props> = ({ src, alt, width = 400, imagePriority, className }) => {
  const height = Math.round(width / IMAGE_ASPECT_RATIO);
  return (
    <Image
      src={src}
      width={width}
      height={height}
      alt={alt}
      className={classNames(styles.root, className)}
      data-testid="ProductCard-component-image"
      loading={imagePriority ? 'eager' : 'lazy'}
    />
  );
};
