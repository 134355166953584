'use client';
import { ComponentProps } from 'react';
import { productDetailPayloadToBaseCarData } from 'src/data/ProductApi/ProductDetailPayloadToBaseCarData';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { PlpMarketingTile } from 'src/plp/components/PlpMarketingTile/PlpMarketingTile';
import { ProductCard } from 'src/plp/components/ProductCard/ProductCard';
import { ProductCardLoading } from 'src/plp/components/ProductCard/ProductCardLoading';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';
import { pushToDataLayerProductCard } from 'src/utils/pushToDataLayer';

export const generateTiles = (
  isLoading: boolean,
  results?: ProductListDataPayload['results'],
  marketingItems?: ComponentProps<typeof PlpMarketingTile>[],
): ComponentProps<typeof CarouselSection>['tiles'] => {
  if (isLoading || !results) {
    return [...Array(4).keys()].map((item) => ({
      id: `${item}`,
      tile: <ProductCardLoading key={`loading-${item}`} />,
    }));
  }

  const tiles = results.map((product, index) => ({
    id: product.sku,
    tile: (
      <ProductCard
        carData={productDetailPayloadToBaseCarData(product)}
        weeklyRepayment={product.weeklyRepayment}
        defaultRate={product.defaultRate}
        variant="default"
        onCardClick={() => {
          pushToDataLayerProductCard(product, index, {
            event: 'carousel_tile_click',
            name: 'recommendedCar',
            value: 'recommendedCar',
          });
        }}
      />
    ),
  }));

  if (marketingItems) {
    const marketingTiles = marketingItems.map((tile) => ({
      id: tile.title ?? '',
      tile: <PlpMarketingTile {...tile} variant="carousel" />,
    }));
    tiles.push(...marketingTiles);
  }

  return tiles;
};
