'use client';

import { ComponentProps, FC, ReactNode } from 'react';
import { FinanceEnquiryPayload, sendFinanceEnquiry } from 'src/services/LeadService';
import { pushToDataLayerWithEnhancedConversion } from 'src/utils/pushToDataLayer';
import { FinanceEnquiryFormValues } from '../../FinanceCalculator/FinanceCalculator.type';
import { FinanceHeroCalculator } from '../../FinanceCalculator/FinanceHeroCalculator/FinanceHeroCalculator';
import { ToasterService } from 'src/general/components/Toaster/ToasterService';

type Props = {
  buttonLabel: ReactNode;
  interestRateRange: {
    min: number;
    max: number;
  };
  termOfLoan: {
    min: number;
    max: number;
  };
  microcopy: ComponentProps<typeof FinanceHeroCalculator>['microcopy'] & {
    errorMessage: string;
  };
};

export const FinanceHeroBannerForm: FC<Props> = ({ buttonLabel, interestRateRange, termOfLoan, microcopy }) => {
  const onSubmitEnquiry = async (data: FinanceEnquiryFormValues) => {
    const payload: FinanceEnquiryPayload = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.emailAddress,
      phone: data.phoneNumber,
      message: data.message,
      deposit_amount: data.depositAmount,
      interest_rate: data.interestRate,
      purchase_price: data.purchasePrice,
      term_of_loan: data.termOfLoan,
      weekly_payment: data.weeklyPayment,
    };
    const response = await sendFinanceEnquiry(payload);
    pushToDataLayerWithEnhancedConversion(data.emailAddress, data.phoneNumber, {
      event: 'finance_enquiry_form_submit',
      value: payload,
    });

    // handle error
    if (response?.status === 400) {
      const message = microcopy.errorMessage;
      ToasterService.error({ message });
      return message;
    }
    ToasterService.success({ message: 'Your enquiry has been sent successfully' });
    return true;
  };

  return (
    <FinanceHeroCalculator
      button={{
        label: buttonLabel,
        buttonType: 'button',
        variant: 'accent',
        size: 'small',
      }}
      interestRateRange={{
        minRate: interestRateRange.min,
        maxRate: interestRateRange.max,
      }}
      loanTerms={termOfLoan}
      onSubmit={onSubmitEnquiry}
      microcopy={microcopy}
    />
  );
};
