import { FC } from 'react';

import { NewsletterSignupLegacy } from 'src/content-hub/components/NewsletterSignup/NewsletterSignupLegacy';
import { HowItWorks } from 'src/hygiene/sections/flexible-content/HowItWorks/HowItWorks';
import { FLEXIBLE_SECTION } from 'src/utils/flexibleSection.types';

import { AccordionSection } from '../flexible-content/AccordionSection/AccordionSection';
import { Awards } from '../flexible-content/Awards/Awards';
import { BrowseCars } from '../flexible-content/BrowseCars/BrowseCars';
import { CTABlock } from '../flexible-content/CTABlock/CTABlock';
import { Callout } from '../flexible-content/Callout/Callout';
import { CarmaBusinessPartners } from '../flexible-content/CarmaBusinessPartners/CarmaBusinessPartners';
import { CarmaSponsorships } from '../flexible-content/CarmaSponsorships/CarmaSponsorships';
import { ComparisonTable } from '../flexible-content/ComparisonTable/ComparisonTable';
import { CustomerReviewsLegacy } from '../flexible-content/CustomerReviews/CustomerReviewsLegacy.';
import { CustomerReviewsScoreLegacy } from '../flexible-content/CustomerReviewsScore/CustomerReviewsLegacy.';
import { Disclaimer } from '../flexible-content/Disclaimer/Disclaimer';
import { Divider } from '../flexible-content/Divider/Divider';
import { FeatureSection } from '../flexible-content/FeatureSection/FeatureSection';
import { FinanceHeroBannerLegacy } from '../flexible-content/FinanceHeroBanner/FinanceHeroBannerLegacy';
import { GenericHero } from '../flexible-content/GenericHero/GenericHero';
import { HeroHomepage } from '../flexible-content/HeroHomepage/HeroHomepage';
import { HeroTradeIn } from '../flexible-content/HeroTradeIn/HeroTradeIn';
import { ImagesCarousel } from '../flexible-content/ImagesCarousel/ImagesCarousel';
import { InlineImage } from '../flexible-content/InlineImage/InlineImage';
import { InlineVideo } from '../flexible-content/InlineVideo/InlineVideo';
import { LinkBlock } from '../flexible-content/LinkBlock/LinkBlock';
import { LogoAndContent } from '../flexible-content/LogoAndContent/LogoAndContent';
import { Marketing } from '../flexible-content/Marketing/Marketing';
import { MarketingBanner } from '../flexible-content/MarketingBanner/MarketingBanner';
import { MediaAndContent } from '../flexible-content/MediaAndContent/MediaAndContent';
import { OptInSection } from '../flexible-content/OptInSection/OptInSection';
import { RecentlyViewedCarousel } from '../flexible-content/RecentlyViewedCarousel/RecentlyViewedCarousel';
import { RecommendedCarsCarousel } from '../flexible-content/RecommendedCarsCarousel/RecommendedCarsCarousel';
import { RelatedArticles } from '../flexible-content/RelatedArticles/RelatedArticles';
import { RichTextLegacy } from '../flexible-content/RichText/RichTextLegacy';
import { TableSection } from '../flexible-content/TableSection/TableSection';
import { VehicleCarousel } from '../flexible-content/VehicleCarousel/VehicleCarousel';
import { VerticalMediaAndContent } from '../flexible-content/VerticalMediaAndContent/VerticalMediaAndContent';
import { FlexibleSection } from '../flexible-content/flexibleContent.type';
import { FlexibleSectionManagerProps, InferFlexibleSectionProps } from './FlexibleSection.type';

const flexibleSectionsMap = new Map<FLEXIBLE_SECTION, FC<FlexibleSection>>([
  [FLEXIBLE_SECTION.CUSTOMER_REVIEWS, CustomerReviewsLegacy],
  [FLEXIBLE_SECTION.CUSTOMER_REVIEWS_SCORE, CustomerReviewsScoreLegacy],
  [FLEXIBLE_SECTION.MEDIA_AND_CONTENT, MediaAndContent],
  [FLEXIBLE_SECTION.MARKETING, Marketing],
  [FLEXIBLE_SECTION.RECENTLY_VIEWED_CAROUSEL, RecentlyViewedCarousel],
  [FLEXIBLE_SECTION.IMAGES_CAROUSEL, ImagesCarousel],
  [FLEXIBLE_SECTION.VEHICLE_CAROUSEL, VehicleCarousel],
  [FLEXIBLE_SECTION.BROWSE_CARS, BrowseCars],
  [FLEXIBLE_SECTION.HERO_TRADE_IN, HeroTradeIn],
  [FLEXIBLE_SECTION.HERO_HOMEPAGE, HeroHomepage],
  [FLEXIBLE_SECTION.CALLOUT_SECTION, Callout],
  [FLEXIBLE_SECTION.FINANCE_HERO_BANNER, FinanceHeroBannerLegacy],
  [FLEXIBLE_SECTION.FEATURE_SECTION, FeatureSection],
  [FLEXIBLE_SECTION.DISCLAIMER_SECTION, Disclaimer],
  [FLEXIBLE_SECTION.RICH_TEXT_SECTION, RichTextLegacy],
  [FLEXIBLE_SECTION.ACCORDION_SECTION, AccordionSection],
  [FLEXIBLE_SECTION.DIVIDER_SECTION, Divider],
  [FLEXIBLE_SECTION.INLINE_IMAGE_SECTION, InlineImage],
  [FLEXIBLE_SECTION.CTA_BLOCK_SECTION, CTABlock],
  [FLEXIBLE_SECTION.INLINE_VIDEO_SECTION, InlineVideo],
  [FLEXIBLE_SECTION.NEWSLETTER_SIGNUP, NewsletterSignupLegacy],
  [FLEXIBLE_SECTION.RELATED_ARTICLE_SECTION, RelatedArticles],
  [FLEXIBLE_SECTION.LINK_BLOCK_SECTION, LinkBlock],
  [FLEXIBLE_SECTION.OPT_IN_SECTION, OptInSection],
  [FLEXIBLE_SECTION.VERTICAL_MEDIA_AND_CONTENT, VerticalMediaAndContent],
  [FLEXIBLE_SECTION.LOGO_AND_CONTENT, LogoAndContent],
  [FLEXIBLE_SECTION.TABLE_SECTION, TableSection],
  [FLEXIBLE_SECTION.AWARDS_SECTION, Awards],
  [FLEXIBLE_SECTION.HOW_IT_WORKS, HowItWorks],
  [FLEXIBLE_SECTION.CARMA_BUSINESS_PARTNERS, CarmaBusinessPartners],
  [FLEXIBLE_SECTION.CARMA_SPONSORSHIPS_SECTION, CarmaSponsorships],
  [FLEXIBLE_SECTION.COMPARISON_TABLE_SECTION, ComparisonTable],
  [FLEXIBLE_SECTION.MARKETING_BANNER, MarketingBanner],
  [FLEXIBLE_SECTION.GENERIC_HERO, GenericHero],
  [FLEXIBLE_SECTION.RECOMMENDED_CARS_CAROUSEL, RecommendedCarsCarousel],
]);

/** @deprecated Please use the non legacy version of this component if you're in NextJS App router */
export const FlexibleSectionManagerLegacy: FC<FlexibleSectionManagerProps> = ({ flexibleSection }) => {
  // Ensures the types of all properties are properly narrowed from the switch statement.
  const { sys, sectionType, sectionBackground, sectionContent, linkedEntries } = {
    sys: flexibleSection.sys,
    sectionType: flexibleSection.sectionContent.sectionType,
    sectionBackground: flexibleSection.sectionBackground,
    sectionContent: flexibleSection.sectionContent,
    linkedEntries: flexibleSection.linkedEntriesCollection.items,
  } as InferFlexibleSectionProps<FlexibleSectionManagerProps>;

  if (flexibleSectionsMap.has(sectionType)) {
    const Component = flexibleSectionsMap.get(sectionType)!;
    return (
      <Component
        sectionData={sectionContent}
        linkedEntries={linkedEntries}
        sys={sys}
        sectionBackground={sectionBackground}
      />
    );
  }

  return null;
};
