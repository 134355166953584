'use client';

import dynamic from 'next/dynamic';
import { FC } from 'react';
import { CAR_PURCHASE_STATUS } from 'src/constants';
import { useStatusPillState } from './useStatusPillState';

interface Props {
  sku: string;
  state: CAR_PURCHASE_STATUS;
}

const StatusPillContent = dynamic(() => import('./StatusPillContent').then((mod) => mod.StatusPillContent), {
  ssr: false,
});

export const StatusPill: FC<Props> = ({ sku, state }) => {
  const { status } = useStatusPillState({ sku, state });

  return <StatusPillContent status={status} />;
};
