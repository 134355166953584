import React, { ComponentProps, useEffect } from 'react';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import styles from './RecentlyViewedCarousel.module.scss';
import { trackFeatureEngagement } from 'src/utils/pushToDataLayer';
import { IntersectionObserverHandler } from 'src/general/components/IntersectionObserverHandler/IntersectionObserverHandler';

type Props = {
  tiles: ComponentProps<typeof CarouselSection>['tiles'];
};

export const RecentlyViewedCarouselSection = ({ tiles }: Props) => {
  useEffect(() => {
    trackFeatureEngagement({
      event: 'feature_engagement',
      payload: {
        type: 'load',
        feature: 'recentlyViewed',
        component: 'carousel',
      },
    });
  }, []);
  return (
    <IntersectionObserverHandler
      margins="-50%"
      onIntersecting={() => {
        trackFeatureEngagement({
          event: 'feature_engagement',
          payload: {
            type: 'view',
            feature: 'recentlyViewed',
            component: 'carousel',
          },
        });
      }}
    >
      <CarouselSection tiles={tiles} className={styles.recentlyViewedCarousel} />
    </IntersectionObserverHandler>
  );
};
