'use client';

import { isEmpty } from 'lodash';
import Image from 'next/image';
import { FC, useMemo } from 'react';
import slugify from 'slugify';
import useSWR from 'swr';

import {
  PageProductListingFilters,
  PageProductListingOptions,
  pageProductListingFiltersValidation,
  pageProductListingOptionsValidation,
} from 'src/data/Contentful/ContentfulGetPageProductListing.types';
import carmaGenericImageLoader from 'src/general/helpers/carmaGenericImageLoader';
import { CarouselTabCars } from 'src/hygiene/components/CarouselTabCars/CarouselTabCars';
import { CarouselTab } from 'src/hygiene/sections/CarouselSectionLegacy/CarouselSectionLegacy.type';
import { plpFilterOptionsFetcher } from 'src/plp/components/PlpFilters/plpFilterOptionsFetcher';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';
import { plpStoreFiltersToFilterParams } from 'src/utils/plp/plpStoreFiltersToFilterParams';

import { CarouselSectionLegacy } from '../../CarouselSectionLegacy/CarouselSectionLegacy';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';

interface Props extends FlexibleSection {
  sectionData: {
    carouselName: string;
    title?: string;
    tabs: {
      title: string;
      icon?: string;
      /** JSON string following the format for PageProductListingFilters */
      filters: string;
      /** Handle the received product list data. */
      handleResponseData?: (data: ProductListDataPayload) => ProductListDataPayload;
      footerBanner?: string;
      cta?: {
        buttonType: string;
        label: string;
        href: string;
      };
    }[];
  };
}

export const VehicleCarousel: FC<Props> = ({ sectionData, sectionBackground }) => {
  const { data: plpFiltersData } = useSWR(
    'vehicle-filters',
    () => plpFilterOptionsFetcher({ includeMakesWithNoResult: false }),
    {
      revalidateOnFocus: true,
      focusThrottleInterval: 300000,
    },
  );

  const tabs: CarouselTab<typeof CarouselTabCars>[] = useMemo(
    () =>
      sectionData.tabs.map(({ title, icon, filters, footerBanner, cta, handleResponseData }, index) => {
        const id = `${slugify(title)}-${index}`;
        let filterParams: PageProductListingFilters = {};
        try {
          filterParams = JSON.parse(filters);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(`Invalid filter data for carousel tab: ${sectionData.carouselName}>${title}`);
          // eslint-disable-next-line no-console
          console.error(error);
        }

        const parsedFilters = (
          pageProductListingFiltersValidation.isValidSync(filterParams) ? filterParams : {}
        ) as PageProductListingFilters;

        const parsedOptions = (
          pageProductListingOptionsValidation.isValidSync(filterParams) ? filterParams : {}
        ) as PageProductListingOptions;

        const queryParams = plpFiltersData
          ? plpStoreFiltersToFilterParams(parsedFilters, parsedOptions, plpFiltersData.filterData)
          : null;

        return {
          id,
          tabLabel: (
            <>
              {!isEmpty(icon) && <Image src={icon!} alt="" width={48} height={24} loader={carmaGenericImageLoader} />}
              {title}
            </>
          ),
          content: {
            queryParams,
            handleResponseData,
            footerBanner,
            cta:
              cta?.buttonType === 'link'
                ? {
                    label: cta?.label,
                    url: cta?.href,
                  }
                : undefined,
          },
        };
      }),
    [sectionData.tabs, plpFiltersData],
  );

  return (
    <CarouselSectionLegacy<typeof CarouselTabCars>
      title={sectionData.title}
      CarouselComponent={CarouselTabCars}
      tabs={tabs}
      carouselName={sectionData.carouselName}
      className={getSectionClasses(sectionBackground)}
    />
  );
};
